.vacancies_filter {
    border-radius: $border-radius;
    padding: 0 20px 20px;
    overflow: hidden;
    background-color: map-deep-get($colors, "brand", "secondary-light");

    @media(max-width:768px){
        margin-bottom: 30px;
    }

    &__title {
        margin: 20px 0 0;
        font-weight: 700;
        display: block;
        color: map-deep-get($colors, "brand", "secondary");
    }

    &__input{
        border-radius: 4px;
    }

    &__list {
        display: flex;
        flex-direction: column;

        li {
            position: relative;
            display: block;
            padding: 11px 0 8px 37px;
        }

        label {
            width: 100%;
            margin: 0;
            cursor: pointer;
            font-size: rem-calc(14.5);
            text-transform: capitalize;
            user-select: none;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -15px;
                height: 30px;
                width: 30px;
                border: 1px solid #000;
                border-radius: $border-radius;
                background-color: #fff;
            }

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -15px;
                height: 30px;
                width: 30px;
                transform: scale(0.7);
            }
        }
        input {
            position: absolute;
            height: 10px;
            width: 10px;
            left: 0;
            top: 0;
            opacity: 0.01;
        }
        input:checked ~ label:after {
            background-image: url(/images/icons/app/check_green.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &__mobile_trigger {
        margin: 18px 0 0;
        font-weight: bold;
        position: relative;

        @media(min-width: 768px){
            display: none;
        }

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            width: 20px;
            height: 20px;
            margin-top: -10px;
            background: url(/images/icons/angle/down.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        &.--open {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    form {
        position: relative;

        @media(max-width: 767px){
            height: 0;
            overflow: hidden;

            &.--open {
                height: auto;
            }
        }
    }
}
