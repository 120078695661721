.vacancies_list {
    display: flex;
    flex-wrap: wrap;

    &__results {
        font-size: rem-calc(14.5);
        opacity: 0.5;
        margin-bottom: 5px;
    }
}

.vacancy_card {
    position: relative;
    width: calc(50% - 20px);
    margin: 0 10px 20px;
    border: 1px solid map-deep-get($colors, "brand", "secondary");
    background: map-deep-get($colors, "white", "default");
    padding: 30px;
    border-radius: $border-radius;

    @media(max-width: 480px){
        width: 100%;
    }

    &__locatie {
        font-size: rem-calc(14);
        text-transform: uppercase;
        font-weight: bold;
        margin: 0;
    }

    &__titel {
        margin: 10px 0;
        font-size: rem-calc(23);
        color: map-deep-get($colors, "brand", "secondary");
        font-weight: bold;
        line-height: 1.2;
    }

    &__text {
        display: block;
        height: 4.5rem;
        overflow: hidden;
        margin: 0;
        @media(max-width: 480px){
            height: auto;
        }

    }
    &__attributes {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 10px 0 30px;
        list-style: none;
        padding: 0;

        li {
            width: 50%;

            @media(max-width: 400px){
                margin-top: 10px;
                width: 100%;
            }
        }

        svg {
            path {
                fill: map-deep-get($colors, "brand", "secondary");
            }
        }

        &__views {
            padding-top: 10px;
            margin-bottom: 0;
            float: right;
            flex-wrap: wrap;
            flex-direction: row;

            @media(max-width:1100px){
                float: left;
            }
        }
    }

    .button {
        position: relative;
    }
}
