.vacancies_alert {
    margin-bottom: 20px;

    $this: &;

    &__box {
        position: relative;
        background-color: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "black", "default");
        border-radius: 4px;
        padding: 6px 6px;
        display: flex;
        cursor: pointer;
        align-items: center;

        &:after {
            content: "";
            position: absolute;
            right: 20px;
            top: 50%;
            width: 20px;
            height: 20px;
            margin-top: -9px;
            background: url(/images/icons/angle/down.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: map-deep-get($colors, "brand", "secondary");
        width: 30px;
        height: 30px;
        border-radius: 50%;

        svg {
            transform: scale(0.9);
            path {
                fill: #fff;
            }
        }
    }

    &__text {
        padding-left: 10px;
        margin: 0;
        font-size: rem-calc(14);
        font-weight: bold;
        text-transform: uppercase;
    }


    &__form_container {
        height: 0;
        position: relative;
        overflow: hidden;
        transition: 0.3s ease;
    }

    &__form {
        background: #fff;
        padding: 30px 50px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .form-message {
        border: none;
    }


    /* OPEN FUNCTIONALITEIT */

    &--open {
        #{$this}__box {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            &:after {
                transform: rotate(180deg);
            }
        }
    }
}
