.icon {
    display: inline;
    position: relative;
    height: 1rem;
    width: 1rem;
    margin-right: 1rem;
    margin-left: 0.5rem;

    $this: &;

    &:before {
        content: "|\00a0\00a0|\00a0\00a0";
    }

    &#{$this}--circle {
        transform: translateX(50%);

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: map-deep-get($colors, "brand", "primary");
        }

        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 18px;
            height: 18px;
            z-index: 1;
        }
    }

    &#{$this}--c_secondary {
        &:after {
            background: map-deep-get($colors, "brand", "secondary");
        }

        svg {
            path {
                fill: map-deep-get($colors, "white", "default");
            }
        }
    }
}
