.header {
    // z-index is om te zorgen dat deze boven de barba laag zit. Hierdoor wordt voor het oog alleen de content vervangen
    background: map-deep-get($colors, "white", "default");
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    height: map_get($header-height, "small");
    transition: $transition-default;
    overflow: hidden;
    $header: &;
    font-size: rem-calc(14);
    border-bottom: 1px solid #ddd;

    @include media-breakpoint-up(md) {
        height: map_get($header-height, "medium");
        overflow: visible;
    }

    @include media-breakpoint-up(xl) {
        height: map_get($header-height, "large");
    }

    &--scrolled {
        background: map-deep-get($colors, "white", "default");
        margin-top: 0;

        @include media-breakpoint-up(md) {
            background: map-deep-get($colors, "white", "default");

            #{$header}__logo {
                height: #{map_get($header-height, "medium--scrolled") - 16px};
                width: 160px;
            }
        }

        @include media-breakpoint-up(xl) {
            #{$header}__logo {
                height: map_get($header-height, "medium--scrolled");
            }
        }
    }

    &--expanded {
        background: map-deep-get($colors, "white", "default");
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }

    &__logo {
        transition: $transition-default;
        width: 69px;
        padding-top: 5px;
        padding-bottom: 5px;
        display: block;

        svg {
            .st0{
                fill-rule:evenodd;
                clip-rule:evenodd;
                fill:#000;
            }
            .st1{
                fill-rule:evenodd;
                clip-rule:evenodd;
                fill:#56ACE0;
            }
            .st2{
                fill:#FCC505;
            }
            .st3{
                fill-rule:evenodd;
                clip-rule:evenodd;
                fill:#194F82;
            }
        }

        @include media-breakpoint-up(md) {
            padding-top: 0;
            padding-bottom: 0;
            width: 150px;
        }

        @include media-breakpoint-up(xl) {
            width: 185px;
        }

        svg {
            width: 100%;
            height: auto;
        }
    }

    &__logo-image {
        height: 100%;
    }

    &__menu-toggle {
        float: right;
        position: relative;
    }


}
