.login_form {
    padding-top: 50px;
    padding-bottom: 50px;

    &__footer {
        .button {
            margin-right: 20px;
        }
    }

    &__forgotlink {
        color: map-deep-get($colors, "brand", "secondary");
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }

        @media(max-width: 768px){
            display: block;
            margin-top: 20px;
        }
    }

    &__link {
        margin-top: -15px;
        font-style: italic;

        a {
            color: map-deep-get($colors, "brand", "secondary");
        }
    }
}


.password_reset {
    padding-top: 50px;
    padding-bottom: 50px;
}
