.modal_edit_location {

    .content_block {
        margin-bottom: 0;
    }

    &__buttons {
        display: flex;

        button {
            margin-right: 20px;
        }
    }
    &__remove {
        @media(max-width: 768px) {
            margin-top: 20px;
        }

        @media(min-width: 768px) {
            float: right;
        }
    }

    &__delete_modal {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    /* IK WEET HET: zwakte bod, kon er niet snel genoeg uit komen */
    @media(max-width: 1024px) and (max-height: 768px){
        .form_modal__container .form_modal__content{
            transform: scale(0.9);
        }
    }
}
