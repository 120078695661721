.vacancies_index {
    margin-bottom: 50px;

    .vacancies_header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 20px 0;
        @media(max-width: 768px){
            flex-direction: column;
            align-items: flex-start;
        }

        &__titel {
            margin: 0;
        }

        &__admin_link {
            svg {
                transform: translateY(-2px);
                path {
                    fill: map-deep-get($colors, "brand", "secondary");
                }
            }
            a {
                letter-spacing: 0.08px;
                color: map-deep-get($colors, "brand", "secondary");
                text-decoration: underline;
            }

            @media(max-width: 768px){
                margin-top: 20px;
            }
        }
    }
}
