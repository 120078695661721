.pagination {
    margin-top: 60px;

    @media(max-width:768px){
        font-size: 0.8rem;
        margin: 30px auto;
    }

    &__list {
        list-style: none;
        display: flex;

        &:first-child {
            border-left: 1px solid map-deep-get($colors, "brand", "secondary");
        }
    }

    &__list-item {
        border: 1px solid map-deep-get($colors, "brand", "secondary");
        border-left: none;
    }

    &__control-link {
        color: map-deep-get($colors, "brand", "secondary");
        font-weight: bold;
        display: inline-block;
        padding: 12px 20px;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
            background: map-deep-get($colors, "brand", "secondary");
            color: map-deep-get($colors, "white", "default");
            text-decoration: none;
        }
    }

    &__list-link {
        color: map-deep-get($colors, "brand", "secondary");
        font-weight: bold;
        display: inline-block;
        padding: 12px 20px;
        text-decoration: none;

        &--active {
            background: map-deep-get($colors, "brand", "secondary");
            color: map-deep-get($colors, "white", "default");
        }

        &:hover {
            background: map-deep-get($colors, "brand", "secondary");
            color: map-deep-get($colors, "white", "default");
            text-decoration: none;
        }
    }
}

