.form_modal {

    &__delete {
        color: map-deep-get($colors, "validation", "invalid");
        cursor: pointer;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }

    &__container {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: 0.3s ease;
        z-index: 1000;
    }

    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.6);
    }
    &--hide {
        pointer-events: none;
        opacity: 0;
    }
    &--show {
        pointer-events: initial;
        opacity: 1;
    }

    &__content {
        position: relative;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__close_button {
        position: absolute;
        right: 20px;
        top: 6px;
        cursor: pointer;
        font-size: 2rem;
    }
}
