.organisation_edit {
    margin-top: 50px;

    .locations, .admin_acount, .admin_account_password {
        margin-top: 50px;
    }

    .locations {
        &__edit_link {
            text-align: right;

            a {
                color: map-deep-get($colors, "brand", "secondary");
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .location_toevoegen {
        color: map-deep-get($colors, "brand", "secondary");
        padding: 40px 0;
        height: calc(100% - 20px);
        transition: 0.3s ease;

        &:hover {
            background: #fff;
        }

        &__icon {
            font-size: 4rem;
            line-height: 0.6;
        }
        &__text {
            font-size: 0.8rem;
            text-transform: uppercase;
            margin: 10px 0 0;
            font-weight: bold;
        }

        .content_block__content {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    }
}
