.admin_vacancy_edit {
    padding-top: 50px;
    @media(max-width:786px){
        padding-top: 0;
        padding-bottom: 50px;
    }

    [name="scope_hours"]{
        width: 3.4rem;
    }

    &__fte-radio {
        margin: 0.6rem 1rem;
    }

    @media(max-width: 992px){
        &__buttons {
            flex-direction: column;
        }
        &__submit {
            margin-bottom: 20px;
        }
    }
}
