.form_fileupload {

   .form__file-input-wrapper {
       display: flex;
       flex-wrap: wrap;

       span {
           width: calc(50% - 10px);
           margin-right: 1rem;
           border: 2px dashed #999;
           padding: 0 1rem;
           line-height: 2.75rem;
           color: #999;
           margin-bottom: 0.5rem;
       }

       input {
           border: transparent;
           position: relative;
           width: 40%;

           &:before {
               content: "";
               position: absolute;
               left: 0;
               top: 0;
               width: 100%;
               height: 100%;
               background: #fff;
           }
           &:after {
               position: absolute;
               content: "upload bestand";
               left: 0;
               top: 0;
               color: #000;
               border: 2px solid map-deep-get($colors, "brand", "primary");
               text-transform: uppercase;
               font-size: 0.8rem;
               font-weight: bold;
               border-radius: 1.6rem;
               padding: 0.8rem;
               z-index: 1;
           }
           &:hover{
               &:after {
                   background: map-deep-get($colors, "brand", "primary");
                   cursor: pointer;
               }
           }
       }

       @media (max-width: 480px){
            span, input {
                width: 100%;
            }
       }
   }
}
