@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,700&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  text-align: left;
  background-color: #E7EFF5; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #FEC601;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #b28b01;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 992px) {
    .container {
      max-width: 1200px; } }

.container-fluid, .container-lg {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1200px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-13, .col-xl-14, .col-xl-15, .col-xl-16, .col-xl-17, .col-xl-18, .col-xl-19, .col-xl-20, .col-xl-21, .col-xl-22, .col-xl-23, .col-xl-24, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-13, .col-xxl-14, .col-xxl-15, .col-xxl-16, .col-xxl-17, .col-xxl-18, .col-xxl-19, .col-xxl-20, .col-xxl-21, .col-xxl-22, .col-xxl-23, .col-xxl-24, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 4.16667%;
  max-width: 4.16667%; }

.col-2 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-3 {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.col-4 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-5 {
  flex: 0 0 20.83333%;
  max-width: 20.83333%; }

.col-6 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-7 {
  flex: 0 0 29.16667%;
  max-width: 29.16667%; }

.col-8 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-9 {
  flex: 0 0 37.5%;
  max-width: 37.5%; }

.col-10 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-11 {
  flex: 0 0 45.83333%;
  max-width: 45.83333%; }

.col-12 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-13 {
  flex: 0 0 54.16667%;
  max-width: 54.16667%; }

.col-14 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-15 {
  flex: 0 0 62.5%;
  max-width: 62.5%; }

.col-16 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-17 {
  flex: 0 0 70.83333%;
  max-width: 70.83333%; }

.col-18 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-19 {
  flex: 0 0 79.16667%;
  max-width: 79.16667%; }

.col-20 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-21 {
  flex: 0 0 87.5%;
  max-width: 87.5%; }

.col-22 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-23 {
  flex: 0 0 95.83333%;
  max-width: 95.83333%; }

.col-24 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 25; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.order-13 {
  order: 13; }

.order-14 {
  order: 14; }

.order-15 {
  order: 15; }

.order-16 {
  order: 16; }

.order-17 {
  order: 17; }

.order-18 {
  order: 18; }

.order-19 {
  order: 19; }

.order-20 {
  order: 20; }

.order-21 {
  order: 21; }

.order-22 {
  order: 22; }

.order-23 {
  order: 23; }

.order-24 {
  order: 24; }

.offset-1 {
  margin-left: 4.16667%; }

.offset-2 {
  margin-left: 8.33333%; }

.offset-3 {
  margin-left: 12.5%; }

.offset-4 {
  margin-left: 16.66667%; }

.offset-5 {
  margin-left: 20.83333%; }

.offset-6 {
  margin-left: 25%; }

.offset-7 {
  margin-left: 29.16667%; }

.offset-8 {
  margin-left: 33.33333%; }

.offset-9 {
  margin-left: 37.5%; }

.offset-10 {
  margin-left: 41.66667%; }

.offset-11 {
  margin-left: 45.83333%; }

.offset-12 {
  margin-left: 50%; }

.offset-13 {
  margin-left: 54.16667%; }

.offset-14 {
  margin-left: 58.33333%; }

.offset-15 {
  margin-left: 62.5%; }

.offset-16 {
  margin-left: 66.66667%; }

.offset-17 {
  margin-left: 70.83333%; }

.offset-18 {
  margin-left: 75%; }

.offset-19 {
  margin-left: 79.16667%; }

.offset-20 {
  margin-left: 83.33333%; }

.offset-21 {
  margin-left: 87.5%; }

.offset-22 {
  margin-left: 91.66667%; }

.offset-23 {
  margin-left: 95.83333%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 25; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .order-sm-13 {
    order: 13; }
  .order-sm-14 {
    order: 14; }
  .order-sm-15 {
    order: 15; }
  .order-sm-16 {
    order: 16; }
  .order-sm-17 {
    order: 17; }
  .order-sm-18 {
    order: 18; }
  .order-sm-19 {
    order: 19; }
  .order-sm-20 {
    order: 20; }
  .order-sm-21 {
    order: 21; }
  .order-sm-22 {
    order: 22; }
  .order-sm-23 {
    order: 23; }
  .order-sm-24 {
    order: 24; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 4.16667%; }
  .offset-sm-2 {
    margin-left: 8.33333%; }
  .offset-sm-3 {
    margin-left: 12.5%; }
  .offset-sm-4 {
    margin-left: 16.66667%; }
  .offset-sm-5 {
    margin-left: 20.83333%; }
  .offset-sm-6 {
    margin-left: 25%; }
  .offset-sm-7 {
    margin-left: 29.16667%; }
  .offset-sm-8 {
    margin-left: 33.33333%; }
  .offset-sm-9 {
    margin-left: 37.5%; }
  .offset-sm-10 {
    margin-left: 41.66667%; }
  .offset-sm-11 {
    margin-left: 45.83333%; }
  .offset-sm-12 {
    margin-left: 50%; }
  .offset-sm-13 {
    margin-left: 54.16667%; }
  .offset-sm-14 {
    margin-left: 58.33333%; }
  .offset-sm-15 {
    margin-left: 62.5%; }
  .offset-sm-16 {
    margin-left: 66.66667%; }
  .offset-sm-17 {
    margin-left: 70.83333%; }
  .offset-sm-18 {
    margin-left: 75%; }
  .offset-sm-19 {
    margin-left: 79.16667%; }
  .offset-sm-20 {
    margin-left: 83.33333%; }
  .offset-sm-21 {
    margin-left: 87.5%; }
  .offset-sm-22 {
    margin-left: 91.66667%; }
  .offset-sm-23 {
    margin-left: 95.83333%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-md-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-md-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-md-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-md-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 25; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .order-md-13 {
    order: 13; }
  .order-md-14 {
    order: 14; }
  .order-md-15 {
    order: 15; }
  .order-md-16 {
    order: 16; }
  .order-md-17 {
    order: 17; }
  .order-md-18 {
    order: 18; }
  .order-md-19 {
    order: 19; }
  .order-md-20 {
    order: 20; }
  .order-md-21 {
    order: 21; }
  .order-md-22 {
    order: 22; }
  .order-md-23 {
    order: 23; }
  .order-md-24 {
    order: 24; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 4.16667%; }
  .offset-md-2 {
    margin-left: 8.33333%; }
  .offset-md-3 {
    margin-left: 12.5%; }
  .offset-md-4 {
    margin-left: 16.66667%; }
  .offset-md-5 {
    margin-left: 20.83333%; }
  .offset-md-6 {
    margin-left: 25%; }
  .offset-md-7 {
    margin-left: 29.16667%; }
  .offset-md-8 {
    margin-left: 33.33333%; }
  .offset-md-9 {
    margin-left: 37.5%; }
  .offset-md-10 {
    margin-left: 41.66667%; }
  .offset-md-11 {
    margin-left: 45.83333%; }
  .offset-md-12 {
    margin-left: 50%; }
  .offset-md-13 {
    margin-left: 54.16667%; }
  .offset-md-14 {
    margin-left: 58.33333%; }
  .offset-md-15 {
    margin-left: 62.5%; }
  .offset-md-16 {
    margin-left: 66.66667%; }
  .offset-md-17 {
    margin-left: 70.83333%; }
  .offset-md-18 {
    margin-left: 75%; }
  .offset-md-19 {
    margin-left: 79.16667%; }
  .offset-md-20 {
    margin-left: 83.33333%; }
  .offset-md-21 {
    margin-left: 87.5%; }
  .offset-md-22 {
    margin-left: 91.66667%; }
  .offset-md-23 {
    margin-left: 95.83333%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 25; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .order-lg-13 {
    order: 13; }
  .order-lg-14 {
    order: 14; }
  .order-lg-15 {
    order: 15; }
  .order-lg-16 {
    order: 16; }
  .order-lg-17 {
    order: 17; }
  .order-lg-18 {
    order: 18; }
  .order-lg-19 {
    order: 19; }
  .order-lg-20 {
    order: 20; }
  .order-lg-21 {
    order: 21; }
  .order-lg-22 {
    order: 22; }
  .order-lg-23 {
    order: 23; }
  .order-lg-24 {
    order: 24; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 4.16667%; }
  .offset-lg-2 {
    margin-left: 8.33333%; }
  .offset-lg-3 {
    margin-left: 12.5%; }
  .offset-lg-4 {
    margin-left: 16.66667%; }
  .offset-lg-5 {
    margin-left: 20.83333%; }
  .offset-lg-6 {
    margin-left: 25%; }
  .offset-lg-7 {
    margin-left: 29.16667%; }
  .offset-lg-8 {
    margin-left: 33.33333%; }
  .offset-lg-9 {
    margin-left: 37.5%; }
  .offset-lg-10 {
    margin-left: 41.66667%; }
  .offset-lg-11 {
    margin-left: 45.83333%; }
  .offset-lg-12 {
    margin-left: 50%; }
  .offset-lg-13 {
    margin-left: 54.16667%; }
  .offset-lg-14 {
    margin-left: 58.33333%; }
  .offset-lg-15 {
    margin-left: 62.5%; }
  .offset-lg-16 {
    margin-left: 66.66667%; }
  .offset-lg-17 {
    margin-left: 70.83333%; }
  .offset-lg-18 {
    margin-left: 75%; }
  .offset-lg-19 {
    margin-left: 79.16667%; }
  .offset-lg-20 {
    margin-left: 83.33333%; }
  .offset-lg-21 {
    margin-left: 87.5%; }
  .offset-lg-22 {
    margin-left: 91.66667%; }
  .offset-lg-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 25; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .order-xl-13 {
    order: 13; }
  .order-xl-14 {
    order: 14; }
  .order-xl-15 {
    order: 15; }
  .order-xl-16 {
    order: 16; }
  .order-xl-17 {
    order: 17; }
  .order-xl-18 {
    order: 18; }
  .order-xl-19 {
    order: 19; }
  .order-xl-20 {
    order: 20; }
  .order-xl-21 {
    order: 21; }
  .order-xl-22 {
    order: 22; }
  .order-xl-23 {
    order: 23; }
  .order-xl-24 {
    order: 24; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 4.16667%; }
  .offset-xl-2 {
    margin-left: 8.33333%; }
  .offset-xl-3 {
    margin-left: 12.5%; }
  .offset-xl-4 {
    margin-left: 16.66667%; }
  .offset-xl-5 {
    margin-left: 20.83333%; }
  .offset-xl-6 {
    margin-left: 25%; }
  .offset-xl-7 {
    margin-left: 29.16667%; }
  .offset-xl-8 {
    margin-left: 33.33333%; }
  .offset-xl-9 {
    margin-left: 37.5%; }
  .offset-xl-10 {
    margin-left: 41.66667%; }
  .offset-xl-11 {
    margin-left: 45.83333%; }
  .offset-xl-12 {
    margin-left: 50%; }
  .offset-xl-13 {
    margin-left: 54.16667%; }
  .offset-xl-14 {
    margin-left: 58.33333%; }
  .offset-xl-15 {
    margin-left: 62.5%; }
  .offset-xl-16 {
    margin-left: 66.66667%; }
  .offset-xl-17 {
    margin-left: 70.83333%; }
  .offset-xl-18 {
    margin-left: 75%; }
  .offset-xl-19 {
    margin-left: 79.16667%; }
  .offset-xl-20 {
    margin-left: 83.33333%; }
  .offset-xl-21 {
    margin-left: 87.5%; }
  .offset-xl-22 {
    margin-left: 91.66667%; }
  .offset-xl-23 {
    margin-left: 95.83333%; } }

@media (min-width: 1600px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 4.16667%;
    max-width: 4.16667%; }
  .col-xxl-2 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-3 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .col-xxl-4 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-5 {
    flex: 0 0 20.83333%;
    max-width: 20.83333%; }
  .col-xxl-6 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-7 {
    flex: 0 0 29.16667%;
    max-width: 29.16667%; }
  .col-xxl-8 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-9 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .col-xxl-10 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-11 {
    flex: 0 0 45.83333%;
    max-width: 45.83333%; }
  .col-xxl-12 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-13 {
    flex: 0 0 54.16667%;
    max-width: 54.16667%; }
  .col-xxl-14 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-15 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .col-xxl-16 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-17 {
    flex: 0 0 70.83333%;
    max-width: 70.83333%; }
  .col-xxl-18 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-19 {
    flex: 0 0 79.16667%;
    max-width: 79.16667%; }
  .col-xxl-20 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-21 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .col-xxl-22 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-23 {
    flex: 0 0 95.83333%;
    max-width: 95.83333%; }
  .col-xxl-24 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 25; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .order-xxl-13 {
    order: 13; }
  .order-xxl-14 {
    order: 14; }
  .order-xxl-15 {
    order: 15; }
  .order-xxl-16 {
    order: 16; }
  .order-xxl-17 {
    order: 17; }
  .order-xxl-18 {
    order: 18; }
  .order-xxl-19 {
    order: 19; }
  .order-xxl-20 {
    order: 20; }
  .order-xxl-21 {
    order: 21; }
  .order-xxl-22 {
    order: 22; }
  .order-xxl-23 {
    order: 23; }
  .order-xxl-24 {
    order: 24; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 4.16667%; }
  .offset-xxl-2 {
    margin-left: 8.33333%; }
  .offset-xxl-3 {
    margin-left: 12.5%; }
  .offset-xxl-4 {
    margin-left: 16.66667%; }
  .offset-xxl-5 {
    margin-left: 20.83333%; }
  .offset-xxl-6 {
    margin-left: 25%; }
  .offset-xxl-7 {
    margin-left: 29.16667%; }
  .offset-xxl-8 {
    margin-left: 33.33333%; }
  .offset-xxl-9 {
    margin-left: 37.5%; }
  .offset-xxl-10 {
    margin-left: 41.66667%; }
  .offset-xxl-11 {
    margin-left: 45.83333%; }
  .offset-xxl-12 {
    margin-left: 50%; }
  .offset-xxl-13 {
    margin-left: 54.16667%; }
  .offset-xxl-14 {
    margin-left: 58.33333%; }
  .offset-xxl-15 {
    margin-left: 62.5%; }
  .offset-xxl-16 {
    margin-left: 66.66667%; }
  .offset-xxl-17 {
    margin-left: 70.83333%; }
  .offset-xxl-18 {
    margin-left: 75%; }
  .offset-xxl-19 {
    margin-left: 79.16667%; }
  .offset-xxl-20 {
    margin-left: 83.33333%; }
  .offset-xxl-21 {
    margin-left: 87.5%; }
  .offset-xxl-22 {
    margin-left: 91.66667%; }
  .offset-xxl-23 {
    margin-left: 95.83333%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 5px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 20px;
  height: 14px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 20px;
    height: 2px;
    background-color: #000000;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -6px; }
  .hamburger-inner::after {
    bottom: -6px; }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger {
  z-index: 11; }
  .hamburger--inverted .hamburger-inner {
    background-color: #000000; }
    .hamburger--inverted .hamburger-inner::after, .hamburger--inverted .hamburger-inner::before {
      background-color: #000000; }

.b-lazy {
  opacity: 0;
  transition: all 0.3s ease-in-out; }
  .b-lazy.b-loaded {
    opacity: 1; }

/*! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: 0; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: #fff;
  cursor: pointer; }

.flickity-button:focus {
  outline: 0;
  box-shadow: 0 0 0 5px #19f; }

.flickity-button:active {
  opacity: .6; }

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none; }

.flickity-button-icon {
  fill: #333; }

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: .25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

.flickity-viewport {
  transition: height .3s ease-in-out; }

*,
*::before,
*::after {
  box-sizing: border-box; }

*:focus {
  outline: none; }

html, body {
  margin: 0 auto;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .02em;
  background-color: #E7EFF5; }

body {
  overflow-y: scroll; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

a {
  text-decoration: none; }

b,
strong {
  font-weight: 700; }

img {
  max-width: 100%;
  width: 100%; }

::selection {
  background-color: #FEC601;
  color: #ffffff; }

.h-100 {
  height: 100%; }

@media (min-width: 768px) {
  .h-md-auto {
    height: auto; } }

.button {
  padding: 0.7rem 1.5rem;
  min-height: 2.4rem;
  line-height: 1;
  font-size: 0.875rem;
  border-radius: 1.2rem;
  background-color: #d1d1d1;
  color: #ffffff;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
  text-align: center;
  display: inline-flex;
  align-items: center; }
  .button:hover {
    text-decoration: none; }
  .button svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: #ffffff; }
  .button--small {
    font-size: .875rem;
    padding: .625rem 1.25rem;
    line-height: 1.25rem; }
    .button--small svg {
      width: 1.25rem;
      height: 1.25rem; }
  .button--wide {
    width: 100%;
    justify-content: center; }
  .button--icon-left svg {
    margin-left: -.25em;
    margin-right: 1em; }
  .button--icon-right svg {
    margin-right: -.25em;
    margin-left: 1em; }
  .button--primary, .form__button {
    border: 2px solid #FEC601;
    background: #FEC601;
    color: #000000;
    text-transform: uppercase; }
    .button--primary:hover, .form__button:hover {
      background: #FEC601;
      color: #000000; }
  .button--ghost {
    background: transparent; }
  .button--secondary {
    background-color: #14609B; }
  .button--black {
    background: #000000; }
  .button--white {
    background: #ffffff;
    color: #FEC601; }
    .button--white svg {
      fill: #FEC601; }
  .button--text {
    background: transparent;
    border: transparent; }
  .button--cancel {
    color: #aaa;
    text-transform: uppercase; }
    .button--cancel:hover {
      background: #ccc;
      color: #fff; }
  .button--delete {
    color: #e50044;
    text-decoration: underline;
    font-weight: normal;
    font-size: 1rem;
    padding-right: 0;
    padding-left: 0; }
    .button--delete:hover {
      color: #e50044;
      text-decoration: none;
      border: transparent;
      background: transparent; }

.textlink {
  background: none;
  border: none;
  color: #14609B;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
  font-size: 1rem;
  width: auto;
  text-align: center;
  display: inline-flex;
  align-items: center;
  position: relative; }
  .textlink svg {
    transition: all 0.3s ease-in-out;
    fill: #14609B;
    height: 1.5rem;
    width: 1.5rem;
    position: relative; }
  .textlink:hover {
    text-decoration: none;
    color: #14609B; }
  .textlink--icon-left svg {
    margin-left: 0;
    margin-right: 1rem; }
  .textlink--icon-left:hover svg {
    margin-left: .25rem;
    margin-right: .75rem; }
  .textlink--icon-right svg {
    margin-left: 1rem;
    margin-right: 0; }
  .textlink--icon-right:hover svg {
    margin-left: 1.25rem;
    margin-right: -.25rem; }
  .textlink--back svg {
    margin-left: 0;
    margin-right: 1rem; }
  .textlink--back:hover svg {
    margin-left: -.25rem;
    margin-right: 1.25rem; }
  .textlink--down svg {
    margin-left: 0;
    margin-right: 1rem;
    top: 0; }
  .textlink--down:hover svg {
    top: .25rem; }
  .textlink--up svg {
    margin-left: 0;
    margin-right: 1rem;
    top: 0; }
  .textlink--up:hover svg {
    top: -.25rem; }

p {
  margin: 0;
  line-height: 1.5;
  margin-bottom: 1.5rem; }

.intro {
  font-size: 1.25rem;
  line-height: 1.25; }

strong {
  font-weight: 700; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 700;
  line-height: 1;
  margin: 0;
  margin-bottom: 1.5rem;
  color: #14609B; }

h1, .h1 {
  font-size: 2rem; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 2.5rem; } }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: 1.8rem; }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: 1.5rem; }
  @media (min-width: 768px) {
    h3, .h3 {
      font-size: 1.8rem; } }

h4, .h4 {
  font-size: 1.25rem; }
  @media (min-width: 768px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.125rem; }
  @media (min-width: 768px) {
    h5, .h5 {
      font-size: 1.25rem; } }

h6, .h6 {
  font-size: 1rem; }

/* stylelint-disable */
.rich-text-area {
  -webkit-font-smoothing: antialiased;
  line-height: 1.7; }
  .rich-text-area .intro {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 1.8em; }
  .rich-text-area img {
    max-width: 100%; }
  .rich-text-area a:not(.button) {
    text-decoration: underline; }
  .rich-text-area ul {
    margin: 1.25rem 0; }
    .rich-text-area ul li {
      position: relative;
      padding-left: 20px;
      margin-bottom: 0; }
      .rich-text-area ul li::before {
        content: "";
        background: #FEC601;
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: .65em; }
    .rich-text-area ul ul {
      margin: 0; }

/* stylelint-enable */
.barba-bg {
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #E7EFF5;
  margin-left: 100%;
  opacity: 0;
  transition: margin 0s linear .3s, opacity .3s ease-in-out; }
  .barba-bg--visible {
    opacity: 1;
    margin-left: 0;
    transition: margin 0s linear, opacity .3s ease-in-out; }

.barba-wrapper {
  height: 100%; }

.barba-container {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%; }

input, select {
  background: #ffffff;
  border: 1px solid #9B9B9B;
  border-radius: 0;
  color: #000000;
  font-family: "Ubuntu", sans-serif;
  height: 3.125rem;
  padding: 0 1rem;
  transition: all 0.3s ease-in-out;
  width: 100%; }
  input:focus, select:focus {
    outline: none;
    border: 1px solid #FEC601; }

textarea {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 0;
  color: #000000;
  font-family: "Ubuntu", sans-serif;
  min-height: 9.375rem;
  padding: 8px 16px;
  resize: vertical;
  transition: border .3s ease-in-out;
  width: 100%; }
  textarea:focus {
    border: 1px solid #FEC601;
    outline: none; }

select {
  appearance: none;
  background: #ffffff;
  color: #000000; }
  select::-ms-expand {
    display: none; }

.select-wrapper {
  position: relative;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .select-wrapper__select {
    height: 3.125rem;
    color: #000000;
    border: 1px solid #cccccc;
    font-weight: 500;
    padding: 0 20px; }
    .select-wrapper__select:focus {
      border: 1px solid #cccccc; }
  .select-wrapper__dropdown-icon {
    position: absolute;
    right: 0.625rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    pointer-events: none; }
  .select-wrapper option {
    font-size: 18px;
    padding: 12px 0; }

.radio-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .radio-wrapper__input-wrapper {
    position: relative;
    width: 1.5625rem;
    height: 1.5625rem;
    margin-right: 0.5rem;
    float: left;
    cursor: pointer;
    flex-shrink: 0; }
    .radio-wrapper__input-wrapper::before {
      content: "";
      position: absolute;
      z-index: 1;
      border-radius: 50%;
      background-color: #ffffff;
      border: 1px solid #000000;
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0; }
  .radio-wrapper__input-dot {
    position: absolute;
    left: 0.3125rem;
    top: 0.3125rem;
    z-index: 2;
    border-radius: 50%;
    background-color: #14609B;
    width: 0.9375rem;
    height: 0.9375rem;
    opacity: 0; }
  .radio-wrapper__input, .radio-wrapper input {
    line-height: 1;
    position: absolute;
    left: -62.4375rem; }
    .radio-wrapper__input:checked, .radio-wrapper input:checked {
      border: 1px solid #FEC601; }
      .radio-wrapper__input:checked + .radio-wrapper__input-dot, .radio-wrapper input:checked + .radio-wrapper__input-dot {
        opacity: 1; }
  .radio-wrapper__label, .radio-wrapper label {
    font-size: 0.9375rem;
    cursor: pointer;
    font-weight: 400; }

.form__radio-inline .form__group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; }

.form__radio-inline .form__label {
  width: 100%; }

.form__radio-inline .radio-wrapper {
  margin-bottom: 0;
  margin-right: 1rem; }

.checkbox-wrapper {
  display: flex;
  align-items: start;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .checkbox-wrapper__input-wrapper {
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
    line-height: 1.75rem; }
  .checkbox-wrapper__input-dot {
    width: 1.875rem;
    height: 1.875rem;
    background-color: transparent;
    border: 1px solid #9B9B9B;
    margin-right: 1.25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 4px; }
    .checkbox-wrapper__input-dot svg {
      opacity: 0;
      fill: #FEC601;
      width: 70%;
      height: 70%; }
  .checkbox-wrapper__input, .checkbox-wrapper input {
    line-height: 1;
    position: absolute;
    left: -62.4375rem; }
    .checkbox-wrapper__input:checked + .checkbox-wrapper__input-dot svg, .checkbox-wrapper input:checked + .checkbox-wrapper__input-dot svg {
      opacity: 1; }
  .checkbox-wrapper__label, .checkbox-wrapper label {
    cursor: pointer;
    align-self: center;
    font-weight: 400;
    text-transform: none; }

.form-message {
  background-color: #d1d1d1;
  border-radius: 4px;
  color: #ffffff;
  padding: 12px 46px 12px 12px;
  margin: 2rem 0;
  position: relative;
  align-items: center;
  display: none;
  min-height: 3rem;
  /* stylelint-disable */
  /* stylelint-enable */ }
  .form-message a {
    color: #ffffff;
    text-decoration: underline; }
  .form-message--active {
    display: flex; }
  .form-message--success {
    background-color: #ffffff;
    color: #14609B;
    border: 1px solid #14609B; }
    .form-message--success .form-message__icon--success {
      display: flex; }
  .form-message--error {
    background-color: #e50044; }
    .form-message--error .form-message__icon--error {
      display: flex; }
  .form-message__icon {
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: none;
    border: 1px solid #ffffff;
    display: none; }
    .form-message__icon svg {
      width: 1rem;
      fill: #ffffff; }

.form {
  overflow: hidden;
  transition: all 0.3s ease-in-out; }
  .form__group-wrapper--half .form__group:last-of-type {
    margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    .form__group-wrapper--half {
      display: flex;
      margin: 0 -0.5rem; }
      .form__group-wrapper--half .form__group {
        margin: 0 0.5rem 1.5rem;
        width: 50%; } }
  .form__group {
    margin-bottom: 1.5rem; }
    .form__group:last-of-type {
      /* margin-bottom: 0; */ }
  .form__invalid-message {
    color: #e50044;
    display: none;
    /* stylelint-disable */
    /* stylelint-enable */ }
    .form__invalid-message[show] {
      display: block; }
  .form__label {
    font-weight: 700;
    display: block; }
  .form__label_addition {
    color: #555;
    font-weight: normal; }
  .form--loading {
    opacity: .5; }

/* stylelint-disable */
.cc-btn {
  flex: none !important; }

.cc-revoke {
  display: none !important; }

/* stylelint-enable */
/* stylelint-disable */
.grecaptcha-badge {
  display: none !important; }

/* stylelint-enable */
.header {
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  height: 50px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  font-size: 0.875rem;
  border-bottom: 1px solid #ddd; }
  @media (min-width: 768px) {
    .header {
      height: 60px;
      overflow: visible; } }
  @media (min-width: 1200px) {
    .header {
      height: 70px; } }
  .header--scrolled {
    background: #ffffff;
    margin-top: 0; }
    @media (min-width: 768px) {
      .header--scrolled {
        background: #ffffff; }
        .header--scrolled .header__logo {
          height: 44px;
          width: 160px; } }
    @media (min-width: 1200px) {
      .header--scrolled .header__logo {
        height: 60px; } }
  .header--expanded {
    background: #ffffff;
    height: 100%;
    overflow: hidden;
    overflow-y: auto; }
  .header__logo {
    transition: all 0.3s ease-in-out;
    width: 69px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: block; }
    .header__logo svg .st0 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #000; }
    .header__logo svg .st1 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #56ACE0; }
    .header__logo svg .st2 {
      fill: #FCC505; }
    .header__logo svg .st3 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #194F82; }
    @media (min-width: 768px) {
      .header__logo {
        padding-top: 0;
        padding-bottom: 0;
        width: 150px; } }
    @media (min-width: 1200px) {
      .header__logo {
        width: 185px; } }
    .header__logo svg {
      width: 100%;
      height: auto; }
  .header__logo-image {
    height: 100%; }
  .header__menu-toggle {
    float: right;
    position: relative; }

.navigation {
  padding: 20px 0;
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .navigation {
      padding: 0;
      margin-left: 10px;
      height: auto;
      display: inline-block;
      flex-wrap: unset;
      width: auto; } }
  @media (min-width: 992px) {
    .navigation {
      margin-left: 30px; } }
  .navigation__wrapper {
    transition: all ease-in-out 0s 1s;
    height: 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-end; }
    @media (min-width: 992px) {
      .navigation__wrapper {
        height: auto;
        overflow: visible; } }
    .navigation__wrapper--active {
      transition: all ease-in-out 0s 0s;
      margin-top: -50px;
      padding-top: 50px;
      height: 100%; }
    @media (min-width: 768px) {
      .navigation__wrapper {
        margin-top: 0;
        padding-top: 0; } }
  .navigation__list {
    width: 100%; }
    @media (min-width: 768px) {
      .navigation__list {
        width: auto; } }
  .navigation__list-item {
    transition: all 0.3s 0.15s ease-in-out;
    opacity: 0;
    display: block;
    position: relative;
    padding: 10px 0;
    text-align: center; }
    @media (min-width: 768px) {
      .navigation__list-item {
        padding: 0;
        margin: 0 10px;
        opacity: 1;
        display: inline-block; } }
    @media (min-width: 768px) {
      .navigation__list-item--has-sub:hover .navigation__item {
        background: #ffffff;
        color: #FEC601;
        margin-top: 10px;
        padding-bottom: 15px; }
      .navigation__list-item--has-sub:hover .navigation__list--sub {
        display: block; }
        .navigation__list-item--has-sub:hover .navigation__list--sub .navigation__item {
          color: #000000;
          margin: 0;
          padding: 5px 0; } }
  .navigation__item {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 900;
    line-height: 1.25;
    color: #000000;
    padding: 5px 0;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    border-bottom: 2px solid transparent; }
    @media (min-width: 768px) {
      .navigation__item {
        text-align: left; } }
    .navigation__item:hover {
      text-decoration: none;
      color: #FEC601; }
    .navigation__item--active {
      text-decoration: none;
      border-bottom: 2px solid #14609B; }
    .navigation__item--button {
      -webkit-transition: all .2s ease-in;
      -moz-transition: all .2s ease-in;
      -o-transition: all .2s ease-in;
      transition: all .2s ease-in;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      border-radius: 100px;
      border: 2px solid #FEC601;
      font-size: .875rem;
      line-height: 1em;
      background-color: #FEC601;
      color: #000000;
      padding: 13px 20px;
      font-weight: 700;
      white-space: normal;
      cursor: pointer;
      text-transform: uppercase; }
      .navigation__item--button:hover {
        background-color: #E4B100;
        color: #ffffff;
        text-decoration: none; }
  .navigation__list--sub {
    display: block;
    padding: 8px; }
    .navigation__list--sub .navigation__list-item {
      display: block;
      width: 100%; }
    .navigation__list--sub .navigation__item {
      font-weight: 600;
      line-height: 1.5em;
      font-size: 1rem; }
    @media (min-width: 768px) {
      .navigation__list--sub {
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
        background: #ffffff;
        color: #000000; } }
  .navigation--active .navigation__list-item {
    opacity: 1;
    padding: 5px 0; }

.main {
  overflow: hidden;
  max-width: 100%;
  padding-top: 70px;
  transition: all 0.3s ease-in-out;
  flex: 1 1 100%;
  height: 100%; }
  @media (min-width: 768px) {
    .main {
      padding-top: 80px; } }
  @media (min-width: 992px) {
    .main {
      padding-top: 90px; } }

.site {
  min-height: 100%; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */ }

.footer {
  background-color: #ffffff;
  background-color: #14609B;
  color: #000000;
  color: #fff;
  position: relative;
  z-index: 15;
  padding-top: 50px;
  flex: 1 1 100%; }
  @media (min-width: 992px) {
    .footer {
      padding-top: 50px; } }
  .footer a:not(.button) {
    color: #ffffff; }
  .footer__bottom {
    padding-top: 30px;
    padding-bottom: 30px;
    /* stylelint-disable */
    /* stylelint-enable */ }
    @media (min-width: 768px) {
      .footer__bottom {
        padding-top: 40px;
        padding-bottom: 40px; } }
    .footer__bottom a:not(.button) {
      color: #ffffff; }
  .footer__logo {
    max-width: 490px;
    padding: 0 20px;
    width: 100%;
    display: block;
    margin: 0 auto; }
    .footer__logo svg .st0 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #fff; }
    .footer__logo svg .st1 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #56ACE0; }
    .footer__logo svg .st2 {
      fill: #FCC505; }
    .footer__logo svg .st3 {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: #194F82; }
  .footer__border {
    border: none;
    border-top: 1px solid #FFFFFF;
    margin: 30px 0; }
  .footer__list {
    display: flex;
    flex-wrap: wrap; }
  .footer__list-item {
    width: 100%;
    flex: 0 0 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 100;
    color: #000000;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    margin-bottom: 0.5em; }
    @media (min-width: 768px) {
      .footer__list-item {
        margin-bottom: 0;
        display: inline-flex;
        width: auto;
        flex: 0 0 auto;
        text-align: left;
        padding-right: 10px;
        align-items: center; }
        .footer__list-item::after {
          content: "";
          width: 0.5em;
          height: 0.5em;
          background: #000000;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px; }
        .footer__list-item:last-child::after {
          content: none; } }
  .footer__item {
    color: inherit; }
    .footer__item:hover {
      text-decoration: underline; }
  .footer .copyright {
    display: inline-block;
    float: left; }
  .footer .privacy {
    margin: 0 0 0 20px;
    font-size: 14px;
    font-weight: 100;
    color: #ffffff;
    -webkit-font-smoothing: antialiased; }
  .footer__reference {
    font-size: 14px;
    font-weight: 100;
    color: #ffffff;
    -webkit-font-smoothing: antialiased;
    display: block;
    text-align: center;
    width: 100%; }
    @media (min-width: 768px) {
      .footer__reference {
        display: inline-block;
        text-align: left; } }
  .footer__webnl {
    transition: all 0.3s ease-in-out;
    color: #ffffff; }
    .footer__webnl:hover {
      text-decoration: underline; }

.vacancies_show .vacancy {
  margin: 20px auto 50px; }
  .vacancies_show .vacancy__header {
    background: #14609B;
    padding: 10px 25px;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 10px; }
    @media (max-width: 480px) {
      .vacancies_show .vacancy__header {
        justify-content: center; }
        .vacancies_show .vacancy__header .button {
          margin-top: 20px; } }
    .vacancies_show .vacancy__header .back-link {
      color: #ffffff;
      text-decoration: none; }
      .vacancies_show .vacancy__header .back-link svg {
        transform: translateY(-2px); }
        .vacancies_show .vacancy__header .back-link svg path {
          fill: #ffffff; }
      .vacancies_show .vacancy__header .back-link:hover {
        text-decoration: none; }
        .vacancies_show .vacancy__header .back-link:hover span {
          text-decoration: underline; }
  .vacancies_show .vacancy__info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .vacancies_show .vacancy__info ul {
      list-style: none;
      display: flex;
      min-width: 50%; }
      @media (max-width: 480px) {
        .vacancies_show .vacancy__info ul {
          min-width: 100%;
          margin-bottom: 20px; } }
      .vacancies_show .vacancy__info ul li {
        width: 50%; }
    .vacancies_show .vacancy__info svg {
      transform: translateY(-2px); }
      .vacancies_show .vacancy__info svg path {
        fill: #14609B; }
  .vacancies_show .vacancy__locatie {
    font-weight: bold;
    text-transform: uppercase; }
  .vacancies_show .vacancy__titel {
    font-size: 2.5rem;
    line-height: 1.2;
    margin: 10px 0 20px; }
  .vacancies_show .vacancy__intro {
    font-weight: bold;
    font-size: 1.1rem; }
  .vacancies_show .vacancy__text h2 {
    font-size: 1.125rem;
    margin-bottom: 10px; }
  .vacancies_show .vacancy__text ul li {
    position: relative;
    padding: 0.2rem 0 0.2rem 1.5rem; }
    .vacancies_show .vacancy__text ul li:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      background-image: url("/images/icons/angle/right_blue.svg");
      transform: translateY(-50%);
      width: 15px;
      height: 15px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat; }
  .vacancies_show .vacancy__end {
    color: #aaa; }

.vacancy_form {
  transform: translateY(30px);
  opacity: 0;
  transition: 0.3s ease;
  height: 0; }
  .vacancy_form--active {
    opacity: 1;
    transform: translateY(0);
    height: auto; }
  .vacancy_form__side_text {
    display: block;
    padding: 2.5rem 0; }
  .vacancy_form .form-message {
    border: none; }

.contact_info {
  list-style: none;
  padding: 0; }
  .contact_info li {
    padding: 0.7rem 0; }
  .contact_info a {
    color: #000; }

.login_form {
  padding-top: 50px;
  padding-bottom: 50px; }
  .login_form__footer .button {
    margin-right: 20px; }
  .login_form__forgotlink {
    color: #14609B;
    text-decoration: underline; }
    .login_form__forgotlink:hover {
      text-decoration: none; }
    @media (max-width: 768px) {
      .login_form__forgotlink {
        display: block;
        margin-top: 20px; } }
  .login_form__link {
    margin-top: -15px;
    font-style: italic; }
    .login_form__link a {
      color: #14609B; }

.password_reset {
  padding-top: 50px;
  padding-bottom: 50px; }

.admin_vacancies_list {
  margin-top: 50px;
  margin-bottom: 50px; }

.vacancies_table__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  margin-top: 10px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #14609B;
  font-size: 0.90625rem; }
  @media (max-width: 480px) {
    .vacancies_table__row {
      flex-wrap: wrap; } }
  .vacancies_table__row--inactive, .vacancies_table__row--expired {
    background: #E7EFF5; }
  .vacancies_table__row--header {
    background: #14609B;
    color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px; }
    .vacancies_table__row--header .vacancies_table__row_titel {
      color: #ffffff;
      font-weight: normal;
      font-size: 1rem; }
    @media (max-width: 480px) {
      .vacancies_table__row--header {
        display: none; } }

.vacancies_table__row_titel {
  width: 30%;
  font-size: 1.125rem;
  font-weight: bold;
  color: #14609B; }
  @media (max-width: 480px) {
    .vacancies_table__row_titel {
      width: 100%; } }

.vacancies_table__row_location {
  width: 30%; }
  @media (max-width: 480px) {
    .vacancies_table__row_location {
      width: 100%;
      margin: 10px 0; } }

.vacancies_table__row_status {
  width: 20%; }
  @media (max-width: 480px) {
    .vacancies_table__row_status {
      width: 50%; } }

.vacancies_table__row_status_icon {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 100%;
  margin-right: 10px; }
  .vacancies_table__row_status_icon:after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background: #83CA3D;
    border-radius: 50%; }
  .vacancies_table__row_status_icon--planned:after {
    background: #FEC601; }
  .vacancies_table__row_status_icon--expired:after, .vacancies_table__row_status_icon--inactive:after {
    background: #CFDAE3; }

.vacancies_table__row_buttons {
  width: 20%; }
  @media (max-width: 480px) {
    .vacancies_table__row_buttons {
      width: 50%; } }
  .vacancies_table__row_buttons .detail {
    margin-right: 20px; }
    .vacancies_table__row_buttons .detail svg path {
      fill: #14609B; }
  .vacancies_table__row_buttons .edit {
    color: #14609B;
    text-decoration: underline; }

.organisation_edit {
  margin-top: 50px; }
  .organisation_edit .locations, .organisation_edit .admin_acount, .organisation_edit .admin_account_password {
    margin-top: 50px; }
  .organisation_edit .locations__edit_link {
    text-align: right; }
    .organisation_edit .locations__edit_link a {
      color: #14609B;
      text-decoration: underline; }
      .organisation_edit .locations__edit_link a:hover {
        text-decoration: none; }
  .organisation_edit .location_toevoegen {
    color: #14609B;
    padding: 40px 0;
    height: calc(100% - 20px);
    transition: 0.3s ease; }
    .organisation_edit .location_toevoegen:hover {
      background: #fff; }
    .organisation_edit .location_toevoegen__icon {
      font-size: 4rem;
      line-height: 0.6; }
    .organisation_edit .location_toevoegen__text {
      font-size: 0.8rem;
      text-transform: uppercase;
      margin: 10px 0 0;
      font-weight: bold; }
    .organisation_edit .location_toevoegen .content_block__content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%; }

.admin_vacancy_edit {
  padding-top: 50px; }
  @media (max-width: 786px) {
    .admin_vacancy_edit {
      padding-top: 0;
      padding-bottom: 50px; } }
  .admin_vacancy_edit [name="scope_hours"] {
    width: 3.4rem; }
  .admin_vacancy_edit__fte-radio {
    margin: 0.6rem 1rem; }
  @media (max-width: 992px) {
    .admin_vacancy_edit__buttons {
      flex-direction: column; }
    .admin_vacancy_edit__submit {
      margin-bottom: 20px; } }

.password_reset .form-message {
  border: none;
  padding: 0; }

.error_404 {
  padding: 50px 0;
  min-height: calc(100vh - 447px); }

.vacancies_index {
  margin-bottom: 50px; }
  .vacancies_index .vacancies_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 20px 0; }
    @media (max-width: 768px) {
      .vacancies_index .vacancies_header {
        flex-direction: column;
        align-items: flex-start; } }
    .vacancies_index .vacancies_header__titel {
      margin: 0; }
    .vacancies_index .vacancies_header__admin_link svg {
      transform: translateY(-2px); }
      .vacancies_index .vacancies_header__admin_link svg path {
        fill: #14609B; }
    .vacancies_index .vacancies_header__admin_link a {
      letter-spacing: 0.08px;
      color: #14609B;
      text-decoration: underline; }
    @media (max-width: 768px) {
      .vacancies_index .vacancies_header__admin_link {
        margin-top: 20px; } }

.vacancies_filter {
  border-radius: 4px;
  padding: 0 20px 20px;
  overflow: hidden;
  background-color: #CFDAE3; }
  @media (max-width: 768px) {
    .vacancies_filter {
      margin-bottom: 30px; } }
  .vacancies_filter__title {
    margin: 20px 0 0;
    font-weight: 700;
    display: block;
    color: #14609B; }
  .vacancies_filter__input {
    border-radius: 4px; }
  .vacancies_filter__list {
    display: flex;
    flex-direction: column; }
    .vacancies_filter__list li {
      position: relative;
      display: block;
      padding: 11px 0 8px 37px; }
    .vacancies_filter__list label {
      width: 100%;
      margin: 0;
      cursor: pointer;
      font-size: 0.90625rem;
      text-transform: capitalize;
      user-select: none; }
      .vacancies_filter__list label:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -15px;
        height: 30px;
        width: 30px;
        border: 1px solid #000;
        border-radius: 4px;
        background-color: #fff; }
      .vacancies_filter__list label:after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -15px;
        height: 30px;
        width: 30px;
        transform: scale(0.7); }
    .vacancies_filter__list input {
      position: absolute;
      height: 10px;
      width: 10px;
      left: 0;
      top: 0;
      opacity: 0.01; }
    .vacancies_filter__list input:checked ~ label:after {
      background-image: url(/images/icons/app/check_green.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat; }
  .vacancies_filter__mobile_trigger {
    margin: 18px 0 0;
    font-weight: bold;
    position: relative; }
    @media (min-width: 768px) {
      .vacancies_filter__mobile_trigger {
        display: none; } }
    .vacancies_filter__mobile_trigger:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      background: url(/images/icons/angle/down.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
    .vacancies_filter__mobile_trigger.--open:after {
      transform: rotate(180deg); }
  .vacancies_filter form {
    position: relative; }
    @media (max-width: 767px) {
      .vacancies_filter form {
        height: 0;
        overflow: hidden; }
        .vacancies_filter form.--open {
          height: auto; } }

.vacancies_alert {
  margin-bottom: 20px;
  /* OPEN FUNCTIONALITEIT */ }
  .vacancies_alert__box {
    position: relative;
    background-color: #FEC601;
    color: #000000;
    border-radius: 4px;
    padding: 6px 6px;
    display: flex;
    cursor: pointer;
    align-items: center; }
    .vacancies_alert__box:after {
      content: "";
      position: absolute;
      right: 20px;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -9px;
      background: url(/images/icons/angle/down.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
  .vacancies_alert__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #14609B;
    width: 30px;
    height: 30px;
    border-radius: 50%; }
    .vacancies_alert__icon svg {
      transform: scale(0.9); }
      .vacancies_alert__icon svg path {
        fill: #fff; }
  .vacancies_alert__text {
    padding-left: 10px;
    margin: 0;
    font-size: 0.875rem;
    font-weight: bold;
    text-transform: uppercase; }
  .vacancies_alert__form_container {
    height: 0;
    position: relative;
    overflow: hidden;
    transition: 0.3s ease; }
  .vacancies_alert__form {
    background: #fff;
    padding: 30px 50px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
  .vacancies_alert .form-message {
    border: none; }
  .vacancies_alert--open .vacancies_alert__box {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
    .vacancies_alert--open .vacancies_alert__box:after {
      transform: rotate(180deg); }

.vacancies_list {
  display: flex;
  flex-wrap: wrap; }
  .vacancies_list__results {
    font-size: 0.90625rem;
    opacity: 0.5;
    margin-bottom: 5px; }

.vacancy_card {
  position: relative;
  width: calc(50% - 20px);
  margin: 0 10px 20px;
  border: 1px solid #14609B;
  background: #ffffff;
  padding: 30px;
  border-radius: 4px; }
  @media (max-width: 480px) {
    .vacancy_card {
      width: 100%; } }
  .vacancy_card__locatie {
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0; }
  .vacancy_card__titel {
    margin: 10px 0;
    font-size: 1.4375rem;
    color: #14609B;
    font-weight: bold;
    line-height: 1.2; }
  .vacancy_card__text {
    display: block;
    height: 4.5rem;
    overflow: hidden;
    margin: 0; }
    @media (max-width: 480px) {
      .vacancy_card__text {
        height: auto; } }
  .vacancy_card__attributes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px 0 30px;
    list-style: none;
    padding: 0; }
    .vacancy_card__attributes li {
      width: 50%; }
      @media (max-width: 400px) {
        .vacancy_card__attributes li {
          margin-top: 10px;
          width: 100%; } }
    .vacancy_card__attributes svg path {
      fill: #14609B; }
    .vacancy_card__attributes__views {
      padding-top: 10px;
      margin-bottom: 0;
      float: right;
      flex-wrap: wrap;
      flex-direction: row; }
      @media (max-width: 1100px) {
        .vacancy_card__attributes__views {
          float: left; } }
  .vacancy_card .button {
    position: relative; }

.content_block {
  background: #ffffff;
  border: 1px solid #14609B;
  border-radius: 4px;
  margin-bottom: 20px; }
  .content_block__header {
    padding: 20px 50px;
    border-bottom: 1px solid #ccc; }
    .content_block__header h1, .content_block__header h2, .content_block__header h3, .content_block__header h4, .content_block__header h5 {
      margin: 0; }
  .content_block__content {
    position: relative;
    padding: 35px 50px; }
    @media (max-width: 768px) {
      .content_block__content {
        padding: 35px 20px; } }
  .content_block--slim .content_block__header {
    padding: 20px 20px; }
  .content_block--slim .content_block__content {
    padding: 20px; }
  .content_block--alt {
    background: #CFDAE3;
    border: 1px solid transparent; }
  .content_block--ghost {
    background: transparent;
    border: 1px solid #14609B; }
  .content_block--modal {
    overflow-y: auto;
    overflow-x: hidden; }
    @media (max-width: 768px) {
      .content_block--modal {
        max-height: calc(100vh - 60px); } }

.icon {
  display: inline;
  position: relative;
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
  margin-left: 0.5rem; }
  .icon:before {
    content: "|\00a0\00a0|\00a0\00a0"; }
  .icon.icon--circle {
    transform: translateX(50%); }
    .icon.icon--circle:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: #FEC601; }
    .icon.icon--circle svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 18px;
      height: 18px;
      z-index: 1; }
  .icon.icon--c_secondary:after {
    background: #14609B; }
  .icon.icon--c_secondary svg path {
    fill: #ffffff; }

.float-right {
  float: right; }

.coverall span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%; }

.pagination {
  margin-top: 60px; }
  @media (max-width: 768px) {
    .pagination {
      font-size: 0.8rem;
      margin: 30px auto; } }
  .pagination__list {
    list-style: none;
    display: flex; }
    .pagination__list:first-child {
      border-left: 1px solid #14609B; }
  .pagination__list-item {
    border: 1px solid #14609B;
    border-left: none; }
  .pagination__control-link {
    color: #14609B;
    font-weight: bold;
    display: inline-block;
    padding: 12px 20px;
    text-decoration: none;
    text-transform: uppercase; }
    .pagination__control-link:hover {
      background: #14609B;
      color: #ffffff;
      text-decoration: none; }
  .pagination__list-link {
    color: #14609B;
    font-weight: bold;
    display: inline-block;
    padding: 12px 20px;
    text-decoration: none; }
    .pagination__list-link--active {
      background: #14609B;
      color: #ffffff; }
    .pagination__list-link:hover {
      background: #14609B;
      color: #ffffff;
      text-decoration: none; }

.form_modal__delete {
  color: #e50044;
  cursor: pointer;
  text-decoration: underline; }
  .form_modal__delete:hover {
    text-decoration: none; }

.form_modal__container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s ease;
  z-index: 1000; }

.form_modal__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); }

.form_modal--hide {
  pointer-events: none;
  opacity: 0; }

.form_modal--show {
  pointer-events: initial;
  opacity: 1; }

.form_modal__content {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.form_modal__close_button {
  position: absolute;
  right: 20px;
  top: 6px;
  cursor: pointer;
  font-size: 2rem; }

.form_fileupload .form__file-input-wrapper {
  display: flex;
  flex-wrap: wrap; }
  .form_fileupload .form__file-input-wrapper span {
    width: calc(50% - 10px);
    margin-right: 1rem;
    border: 2px dashed #999;
    padding: 0 1rem;
    line-height: 2.75rem;
    color: #999;
    margin-bottom: 0.5rem; }
  .form_fileupload .form__file-input-wrapper input {
    border: transparent;
    position: relative;
    width: 40%; }
    .form_fileupload .form__file-input-wrapper input:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #fff; }
    .form_fileupload .form__file-input-wrapper input:after {
      position: absolute;
      content: "upload bestand";
      left: 0;
      top: 0;
      color: #000;
      border: 2px solid #FEC601;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: bold;
      border-radius: 1.6rem;
      padding: 0.8rem;
      z-index: 1; }
    .form_fileupload .form__file-input-wrapper input:hover:after {
      background: #FEC601;
      cursor: pointer; }
  @media (max-width: 480px) {
    .form_fileupload .form__file-input-wrapper span, .form_fileupload .form__file-input-wrapper input {
      width: 100%; } }

.form_multiselect {
  position: relative;
  margin-bottom: 2rem; }
  .form_multiselect__values {
    position: absolute;
    left: 0;
    bottom: calc(100% - 15px);
    width: 100%;
    background: #fdfdfd;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px 20px 0;
    opacity: 0;
    transform: translateY(2rem);
    transition: 0.3s ease;
    pointer-events: none; }
    .form_multiselect__values .form__group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
      .form_multiselect__values .form__group .form__valueWrapper {
        width: 50%; }
        @media (max-width: 480px) {
          .form_multiselect__values .form__group .form__valueWrapper {
            width: 100%; } }
  .form_multiselect--open .form_multiselect__values {
    opacity: 1;
    transform: translateY(0);
    pointer-events: initial; }
  .form_multiselect__selection {
    position: relative;
    cursor: pointer; }
    .form_multiselect__selection input {
      pointer-events: none; }
    .form_multiselect__selection:after {
      content: "";
      position: absolute;
      right: 20px;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -9px;
      background: url(/images/icons/angle/down.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(180deg); }
  .form_multiselect--open .form_multiselect__selection:after {
    transform: rotate(0); }

.sidebar_banner__img {
  display: inline-block;
  margin-bottom: 20px; }

.admin_header .navigation__list {
  display: flex;
  align-items: center; }

@media (min-width: 768px) and (max-width: 992px) {
  .admin_header .navigation__wrapper {
    height: auto; } }

@media (max-width: 767px) {
  .admin_header .navigation__list {
    display: inline-block;
    align-items: center; } }

.admin_header .header--scrolled .header__logo,
.admin_header .header__logo {
  height: 100%;
  display: flex;
  align-items: center; }

.modal_edit_location {
  /* IK WEET HET: zwakte bod, kon er niet snel genoeg uit komen */ }
  .modal_edit_location .content_block {
    margin-bottom: 0; }
  .modal_edit_location__buttons {
    display: flex; }
    .modal_edit_location__buttons button {
      margin-right: 20px; }
  @media (max-width: 768px) {
    .modal_edit_location__remove {
      margin-top: 20px; } }
  @media (min-width: 768px) {
    .modal_edit_location__remove {
      float: right; } }
  .modal_edit_location__delete_modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  @media (max-width: 1024px) and (max-height: 768px) {
    .modal_edit_location .form_modal__container .form_modal__content {
      transform: scale(0.9); } }

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 2rem 0; }
  .video-wrapper__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
