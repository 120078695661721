.content_block {
    background: map-deep-get($colors, "white", "default");
    border: 1px solid map-deep-get($colors, "brand", "secondary");
    border-radius: $border-radius;
    margin-bottom: 20px;


    $this: &;

    $padding-side: 50px;
    $padding-side-slim: 20px;

    &__header {
        padding: 20px $padding-side;
        border-bottom: 1px solid #ccc;

        h1,h2,h3,h4,h5 {
            margin: 0;
        }
    }

    &__content {
        position: relative;
        padding: 35px $padding-side;

        @media(max-width:768px){
            padding: 35px $padding-side-slim;
        }
    }

    &--slim {
        #{$this}__header {
            padding: 20px $padding-side-slim;
        }
        #{$this}__content {
            padding: $padding-side-slim;
        }
    }

    &--alt {
        background: map-deep-get($colors, "brand", "secondary-light");
        border: 1px solid transparent;
    }

    &--ghost {
        background: transparent;
        border: 1px solid map-deep-get($colors, "brand", "secondary");
    }

    &--modal {

        overflow-y: auto;
        overflow-x: hidden;

        @media(max-width: 768px){
            max-height: calc(100vh - 60px);
        }
    }
}
