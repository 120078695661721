.footer {
    background-color: map-deep-get($colors, "white", "default");
    background-color: #14609B;
    color: map-deep-get($colors, "black", "default");
    color: #fff;
    position: relative;
    z-index: 15;
    padding-top: 50px;
    flex: 1 1 100%;

    @include media-breakpoint-up(lg) {
        padding-top: 50px;
    }

    a:not(.button) {
        color: map-deep-get($colors, "white", "default");
    }

    &__bottom {
        padding-top: 30px;
        padding-bottom: 30px;

        @include media-breakpoint-up(md) {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        /* stylelint-disable */
        a:not(.button) {
            color: map-deep-get($colors, "white", "default");
        }
        /* stylelint-enable */
    }

    &__logo {
        max-width: 490px;
        padding: 0 20px;
        width: 100%;
        display: block;
        margin: 0 auto;

        svg {
            .st0{
                fill-rule:evenodd;
                clip-rule:evenodd;
                fill:#fff;
            }
            .st1{
                fill-rule:evenodd;
                clip-rule:evenodd;
                fill:#56ACE0;
            }
            .st2{
                fill:#FCC505;
            }
            .st3{
                fill-rule:evenodd;
                clip-rule:evenodd;
                fill:#194F82;
            }
        }
    }

    &__border {
        border: none;
        border-top: 1px solid #FFFFFF;
        margin: 30px 0;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__list-item {
        width: 100%;
        flex: 0 0 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 100;
        color: map-deep-get($colors, "black", "default");
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        margin-bottom: 0.5em;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            display: inline-flex;
            width: auto;
            flex: 0 0 auto;
            text-align: left;
            padding-right: 10px;
            align-items: center;

            &::after {
                content: "";
                width: 0.5em;
                height: 0.5em;
                background: map-deep-get($colors, "black", "default");
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
            }

            &:last-child::after {
                content: none;
            }
        }
    }

    &__item {
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }

    .copyright{
        display: inline-block;
        float: left;
    }
    .privacy{
        margin: 0 0 0 20px ;
        font-size: 14px;
        font-weight: 100;
        color: map-deep-get($colors, "white", "default");
        -webkit-font-smoothing: antialiased;
    }

    &__reference {
        font-size: 14px;
        font-weight: 100;
        color: map-deep-get($colors, "white", "default");
        -webkit-font-smoothing: antialiased;
        display: block;
        text-align: center;
        width: 100%;

        @include media-breakpoint-up(md) {
            display: inline-block;
            text-align: left;
        }
    }

    &__webnl {
        transition: $transition-default;
        color: map-deep-get($colors, "white", "default");

        &:hover {
            text-decoration: underline;
        }
    }
}
