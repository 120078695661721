.button {
    padding: 0.7rem 1.5rem;
    min-height: 2.4rem;
    line-height: 1;
    font-size: rem-calc(14);
    border-radius: 1.2rem;
    background-color: map-deep-get($colors, "gray", "dark");
    color: map-deep-get($colors, "white", "default");
    border: 1px solid transparent;
    transition: $transition-default;
    font-weight: 700;
    text-align: center;
    display: inline-flex;
    align-items: center;

    &:hover {
        text-decoration: none;
    }

    svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: map-deep-get($colors, "white", "default");
    }

    &--small {
        font-size: .875rem;
        padding: .625rem 1.25rem;
        line-height: 1.25rem;

        svg {
            width: 1.25rem;
            height: 1.25rem;
        }
    }

    &--wide {
        width: 100%;
        justify-content: center;
    }

    &--icon-left {
        svg {
            margin-left: -.25em;
            margin-right: 1em;
        }
    }

    &--icon-right {
        svg {
            margin-right: -.25em;
            margin-left: 1em;
        }
    }

    &--primary {
        border: 2px solid map-deep-get($colors, "brand", "primary");
        background: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "black", "default");
        text-transform: uppercase;

        &:hover {
            background: map-deep-get($colors, "brand", "primary");
            color: map-deep-get($colors, "black", "default");
        }
    }

    &--ghost {
        background: transparent;
    }

    &--secondary {
        background-color: map-deep-get($colors, "brand", "secondary");
    }

    &--black {
        background: map-deep-get($colors, "black", "default");
    }

    &--white {
        background: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "brand", "primary");

        svg {
            fill: map-deep-get($colors, "brand", "primary");
        }
    }

    &--text {
        background: transparent;
        border: transparent;
    }

    &--cancel {
        color: #aaa;
        text-transform: uppercase;

        &:hover {
            background: #ccc;
            color: #fff;
        }
    }

    &--delete {
        color: map-deep-get($colors, "validation", "invalid");
        text-decoration: underline;
        font-weight: normal;
        font-size: 1rem;
        padding-right: 0;
        padding-left: 0;

        &:hover {
            color: map-deep-get($colors, "validation", "invalid");
            text-decoration: none;
            border: transparent;
            background: transparent;
        }
    }
}
