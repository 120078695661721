.vacancies_show {

    .vacancy {
        margin: 20px auto 50px;

        &__header {
            background: map-deep-get($colors, "brand", "secondary");
            padding: 10px 25px;
            color: #fff;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            border-radius: $border-radius;
            margin-bottom: 10px;

            @media(max-width:480px){
                justify-content: center;

                .button {
                    margin-top: 20px;
                }
            }

            .back-link {
                color: map-deep-get($colors, "white", "default");
                text-decoration: none;

                svg {
                    transform: translateY(-2px);
                    path {
                        fill: map-deep-get($colors, "white", "default");
                    }
                }

                &:hover {
                    text-decoration: none;

                    span {
                        text-decoration: underline;
                    }
                }
            }

        }

        &__info {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            ul {
                list-style: none;
                display: flex;
                min-width: 50%;
                @media(max-width: 480px){
                    min-width: 100%;
                    margin-bottom: 20px;
                }
                li {
                    width: 50%;
                }
            }

            svg {
                transform: translateY(-2px);
                path {
                    fill: map-deep-get($colors, "brand", "secondary");
                }
            }

        }

        &__locatie {
            font-weight: bold;
            text-transform: uppercase;
        }

        &__titel {
            font-size: rem-calc(40);
            line-height: 1.2;
            margin: 10px 0 20px;
        }

        &__intro {
            font-weight: bold;
            font-size: 1.1rem;
        }

        &__text {
            h2 {
                font-size: rem-calc(18);
                margin-bottom: 10px;
            }

            ul {
                li {
                    position: relative;
                    padding: 0.2rem 0 0.2rem 1.5rem;

                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        background-image: url('/images/icons/angle/right_blue.svg');
                        transform: translateY(-50%);
                        width: 15px;
                        height: 15px;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }
        }

        &__end {
            color: #aaa;
        }

    }
}

.vacancy_form {
    transform: translateY(30px);
    opacity: 0;
    transition: 0.3s ease;
    height: 0;

    &--active {
        opacity: 1;
        transform: translateY(0);
        height: auto;
    }

    &__side_text {
        display: block;
        padding: 2.5rem 0;

        @media (max-width: 768px){

        }
    }
    .form-message {
        border: none;
    }
}

.contact_info {
    list-style: none;
    padding: 0;

    li {
        padding: 0.7rem 0;
    }

    a {
        color: #000;
    }
}
