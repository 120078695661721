.admin_vacancies_list {
    margin-top: 50px;
    margin-bottom: 50px;
}

.vacancies_table {

    $this: &;

    &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px;
        margin-top: 10px;
        background: map-deep-get($colors, "white", "default");
        border-radius: $border-radius;
        border: 1px solid map-deep-get($colors, "brand", "secondary");
        font-size: rem-calc(14.5);
        @media(max-width: 480px){
            flex-wrap: wrap;
        }

        &--inactive, &--expired {
            background: #E7EFF5;
        }

        &--header {
            background: map-deep-get($colors, "brand", "secondary");
            color: map-deep-get($colors, "white", "default");
            padding-top: 5px;
            padding-bottom: 5px;

            & #{$this}__row_titel {
                color: map-deep-get($colors, "white", "default");
                font-weight: normal;
                font-size: 1rem;
            }

            @media(max-width: 480px){
                display: none;
            }
        }
    }

    &__row_titel {
        width: 30%;
        font-size: rem-calc(18);
        font-weight: bold;
        color: map-deep-get($colors, "brand", "secondary");
        @media(max-width: 480px){
            width: 100%;
        }
    }
    &__row_location {
        width: 30%;
        @media(max-width: 480px){
            width: 100%;
            margin: 10px 0;
        }
    }
    &__row_status {
        width: 20%;
        @media(max-width: 480px){
            width: 50%;
        }
    }
    &__row_status_icon {
        position: relative;
        display: inline-block;
        width: 15px;
        height: 100%;
        margin-right: 10px;

        &:after {
            content: "";
            position: absolute;
            width: 15px;
            height: 15px;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            background: #83CA3D;
            border-radius: 50%;
        }

        &--planned {
            &:after {
                background: #FEC601;
            }
        }
        &--expired, &--inactive {
            &:after {
                background: #CFDAE3;
            }
        }
    }
    &__row_buttons {
        width: 20%;

        @media(max-width: 480px){
            width: 50%;
        }

        .detail {
            margin-right: 20px;

            svg {
                path {
                    fill: map-deep-get($colors, "brand", "secondary");
                }
            }
        }
        .edit {
            color: map-deep-get($colors, "brand", "secondary");
            text-decoration: underline;
        }
    }
}
