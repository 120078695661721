.navigation {
    $elm: &;
    padding: 20px 0;
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
        padding: 0;
        margin-left: 10px;
        height: auto;
        display: inline-block;
        flex-wrap: unset;
        width: auto;
    }

    @include media-breakpoint-up(lg) {
        margin-left: 30px;
    }

    &__wrapper {
        transition: all ease-in-out 0s 1s;
        height: 0;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;

        @include media-breakpoint-up(lg) {
            height: auto;
            overflow: visible;
        }

        &--active {
            transition: all ease-in-out 0s 0s;
            margin-top: -#{map-get($header-height, "small")};
            padding-top: #{map-get($header-height, "small")};
            height: 100%;
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
            padding-top: 0;
        }
    }

    &__list {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: auto;
        }
    }

    &__list-item {
        transition: all $transition-default-time #{$transition-default-time / 2} $transition-default-smoothing;
        opacity: 0;
        display: block;
        position: relative;
        padding: 10px 0;
        text-align: center;

        @include media-breakpoint-up(md) {
            padding: 0;
            margin: 0 10px;
            opacity: 1;
            display: inline-block;
        }

        @include media-breakpoint-up(md) {
            &--has-sub:hover {
                #{$elm}__item {
                    background: map-deep-get($colors, "white", "default");
                    color: map-deep-get($colors, "brand", "primary");
                    margin-top: 10px;
                    padding-bottom: 15px;
                }

                #{$elm}__list--sub {
                    display: block;

                    #{$elm}__item {
                        color: map-deep-get($colors, "black", "default");
                        margin: 0;
                        padding: 5px 0;
                    }
                }
            }
        }
    }

    &__item {
        display: inline-block;
        font-size: rem-calc(14);
        font-weight: 900;
        line-height: 1.25;
        color: map-deep-get($colors, "black", "default");
        padding: 5px 0;
        white-space: nowrap;
        transition: $transition-default;
        text-transform: uppercase;
        border-bottom: 2px solid transparent;

        @include media-breakpoint-up(md) {
            text-align: left;
        }

        &:hover {
            text-decoration: none;
            color: map-deep-get($colors, "brand", "primary");
        }

        &--active {
            text-decoration: none;
            border-bottom: 2px solid map-deep-get($colors, "brand", "secondary");
        }

        &--button {
            -webkit-transition: all .2s ease-in;
            -moz-transition: all .2s ease-in;
            -o-transition: all .2s ease-in;
            transition: all .2s ease-in;
            -webkit-border-radius: 100px;
            -moz-border-radius: 100px;
            border-radius: 100px;
            border: 2px solid map-deep-get($colors, "brand", "primary");
            font-size: .875rem;
            line-height: 1em;
            background-color: map-deep-get($colors, "brand", "primary");
            color: map-deep-get($colors, "black", "default");
            padding: 13px 20px;
            font-weight: 700;
            white-space: normal;
            cursor: pointer;
            text-transform: uppercase;

            &:hover {
                background-color: map-deep-get($colors, "brand", "primary-dark");
                color: map-deep-get($colors, "white", "default");
                text-decoration: none;
            }
        }
    }

    &__list--sub {
        display: block;
        padding: 8px;

        #{$elm}__list-item {
            display: block;
            width: 100%;
        }

        #{$elm}__item {
            font-weight: 600;
            line-height: 1.5em;
            font-size: rem-calc(16);
        }

        @include media-breakpoint-up(md) {
            position: absolute;
            top: 100%;
            left: 0;
            display: none;
            background: map-deep-get($colors, "white", "default");
            color: map-deep-get($colors, "black", "default");
        }
    }

    &--active {
        #{$elm}__list-item {
            opacity: 1;
            padding: 5px 0;
        }
    }
}
