.admin_header {

    .navigation__list {
        display: flex;
        align-items: center;
    }

    @media(min-width: 768px) and (max-width: 992px){
        .navigation__wrapper {
            height: auto;
        }
    }
    @media(max-width: 767px){
        .navigation__list {
            display: inline-block;
            align-items: center;
        }
    }

    .header--scrolled .header__logo,
    .header__logo {
        height: 100%;
        display: flex;
        align-items: center;
    }
}
