.float-right {
    float: right;
}
.coverall span {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
