// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    -webkit-font-smoothing: antialiased;
    line-height: 1.7;

    .intro {
        font-size: rem-calc(20);
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 1.8em;
    }

    img {
        max-width: 100%;
    }

    a:not(.button) {
        text-decoration: underline;
    }

    ul {
        margin: rem-calc(20) 0;

        li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 0;

            &::before {
                content: "";
                background: map-deep-get($colors, "brand", "primary");
                width: 0.5em;
                height: 0.5em;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: .65em;
            }
        }

        ul {
            margin: 0;
        }
    }
}

/* stylelint-enable */
