.textlink {
    background: none;
    border: none;
    color: map-deep-get($colors, "brand", "secondary");
    transition: $transition-default;
    font-weight: 700;
    font-size: 1rem;
    width: auto;
    text-align: center;
    display: inline-flex;
    align-items: center;
    position: relative;

    svg {
        transition: $transition-default;
        fill: map-deep-get($colors, "brand", "secondary");
        height: 1.5rem;
        width: 1.5rem;
        position: relative;
    }

    &:hover {
        text-decoration: none;
        color: map-deep-get($colors, "brand", "secondary");
    }

    &--icon-left {
        svg {
            margin-left: 0;
            margin-right: 1rem;
        }

        &:hover {
            svg {
                margin-left: .25rem;
                margin-right: .75rem;
            }
        }
    }

    &--icon-right {
        svg {
            margin-left: 1rem;
            margin-right: 0;
        }

        &:hover {
            svg {
                margin-left: 1.25rem;
                margin-right: -.25rem;
            }
        }
    }

    &--back {
        svg {
            margin-left: 0;
            margin-right: 1rem;
        }

        &:hover {
            svg {
                margin-left: -.25rem;
                margin-right: 1.25rem;
            }
        }
    }

    &--down {
        svg {
            margin-left: 0;
            margin-right: 1rem;
            top: 0;
        }

        &:hover {
            svg {
                top: .25rem;
            }
        }
    }

    &--up {
        svg {
            margin-left: 0;
            margin-right: 1rem;
            top: 0;
        }

        &:hover {
            svg {
                top: -.25rem;
            }
        }
    }
}
