.form_multiselect {
    position: relative;
    margin-bottom: 2rem;

    $this: &;

    &__values {
        position: absolute;
        left: 0;
        bottom: calc(100% - 15px);
        width: 100%;
        background: #fdfdfd;
        border: 1px solid #ccc;
        border-radius: $border-radius;
        padding: 20px 20px 0;
        opacity: 0;
        transform: translateY(2rem);
        transition: 0.3s ease;
        pointer-events: none;

        .form__group {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .form__valueWrapper {
                width: 50%;
                @media(max-width: 480px){
                    width: 100%;
                }
            }
        }
    }

    &--open {
        #{$this}__values {
            opacity: 1;
            transform: translateY(0);
            pointer-events: initial;
        }
    }

    &__selection {
        position: relative;
        cursor: pointer;
        input {
            pointer-events: none;
        }

        &:after {
            content: "";
            position: absolute;
            right: 20px;
            top: 50%;
            width: 20px;
            height: 20px;
            margin-top: -9px;
            background: url(/images/icons/angle/down.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            transform: rotate(180deg);
        }
    }

    &--open {
        #{$this}__selection {
            &:after {
                transform: rotate(0);
            }
        }
    }
}
